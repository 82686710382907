<template>
    <div>
        <div v-permission="['contract.create',]">
            <div class="row">
                <div class="col-md-12">
                    <KTPortlet
                            v-bind="{
            }"
                    >
                        <template v-slot:body>
                            <b-button-toolbar
                                    aria-label="Toolbar with button groups and input groups"
                            >
                                <b-button-group size="sm" class="mr-1">
                                    <b-button v-permission="'contract.fill'" :to="{'name':'document-form',params:{'did':currentEditId()}}" class="mr-2 btn-primary">Visualizza form</b-button>
                                    <b-button v-permission="'contract.fill'" :to="{'name':'document-history',query:{'filter_contract':currentEditId()}}" class="mr-2 btn-primary">Visualizza storico</b-button>
                                </b-button-group>
                            </b-button-toolbar>
                        </template>
                    </KTPortlet>
                </div>

            </div>
        </div>
        <div class="row" v-permission="['contract.create','contract.edit']">
            <div class="col-md-12">



                                <KTPortlet
                        v-bind="{
            }"
                        v-bind:class="{ 'kt-spinner-f': loading }"
                >
                                    <template v-slot:title>
                                        <h4 class="">Configurazione</h4>
                                    </template>
                    <template v-slot:body>
<!--                        <b-form-group-->
<!--                                label-cols="4"-->
<!--                                label-cols-lg="2"-->
<!--                                label-size="sm"-->
<!--                                label="Small"-->
<!--                                label-for="input-sm"-->
<!--                        >-->
<!--                            <b-form-input id="input-sm" size="sm"></b-form-input>-->
<!--                        </b-form-group>-->
                        <b-form @submit="onSubmit" @reset="onReset">
                        <b-form-group
                                label-cols="4"
                                label-cols-lg="2"
                                label="Titolo"
                                label-for="label"
                        >
                            <b-form-input
                                    id="label"
                                    v-model="form.label"
                                    type="text"
                                    required
                                    placeholder="Inserisci il Titolo"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                                label-cols="4"
                                label-cols-lg="2"
                                label="Account firma"
                                label-for="label"
                        >
                            <b-form-select
                                    class="mb-2 mr-sm-2 mb-sm-0"
                                    v-model="form.esignatureaccount"
                                    :options="formoptions.esignatureaccount"
                                    id="inline-form-custom-select-pref"
                                    required
                            >
                                <template v-slot:first>
                                    <option :value="null">-</option>
                                </template>
                            </b-form-select>
                        </b-form-group>

                        <b-form-group
                                    label-cols="4"
                                    label-cols-lg="2"
                                    label="Abilita Reminder"
                                    label-for="label"
                            >
                            <b-form-checkbox v-model="form.opts.EnableReminders" class="mb-2 mr-sm-2 mb-sm-0">
<!--                                Abilita Reminder-->
                            </b-form-checkbox>
                        </b-form-group>
                            <b-form-group
                                    label-cols="4"
                                    label-cols-lg="2"
                                    label="Giorni dal primo avviso"
                                    label-for="label"
                            >
                                <b-form-input
                                        id="label"
                                        v-model="form.opts.FirstReminderDayAmount"
                                        type="text"
                                        placeholder="Giorni dal primo avviso"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    label-cols="4"
                                    label-cols-lg="2"
                                    label="Giorni tra avvisi successivi"
                                    label-for="label"
                            >
                                <b-form-input
                                        id="label"
                                        v-model="form.opts.RecurrentReminderDayAmount"
                                        type="text"
                                        placeholder="Giorni tra avvisi successivi"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    label-cols="4"
                                    label-cols-lg="2"
                                    label="Giorni prima della scadenza"
                                    label-for="label"
                            >
                                <b-form-input
                                        id="label"
                                        v-model="form.opts.BeforeExpirationDayAmount"
                                        type="text"
                                        placeholder="Giorni prima della scadenza"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    label-cols="4"
                                    label-cols-lg="2"
                                    label="Giorni fino alla scadenza"
                                    label-for="label"
                            >
                                <b-form-input
                                        id="label"
                                        v-model="form.opts.DaysUntilExpire"
                                        type="text"
                                        placeholder="Giorni fino alla scadenza"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    label-cols="4"
                                    label-cols-lg="2"
                                    label="Notifica scadenza"
                                    label-for="label"
                            >
                                <b-form-checkbox v-model="form.opts.ExpiredNotification" class="mb-2 mr-sm-2 mb-sm-0">
                                </b-form-checkbox>
                            </b-form-group>
                            <b-form-group
                                    label-cols="4"
                                    label-cols-lg="2"
                                    label="Priorità"
                                    label-for="label"
                            >
                                <b-form-input
                                        id="label"
                                        v-model="form.opts.Priority"
                                        type="text"
                                        placeholder="Priorità (più alto prima, default 10)"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    label-cols="4"
                                    label-cols-lg="2"
                                    label="Configurazione form"
                                    label-for="label"
                                    v-if="allowEditFinalForm()"
                            >
                                <b-form-textarea v-model="finalformconfiguration" class="mb-2 mr-sm-2 mb-sm-0">
                                </b-form-textarea>
                            </b-form-group>

<!--                        <b-card bg-variant="light">-->
<!--                            <b-form-group-->
<!--                                    label-cols-lg="3"-->
<!--                                    label="Shipping Address"-->
<!--                                    label-size="lg"-->
<!--                                    label-class="font-weight-bold pt-0"-->
<!--                                    class="mb-0"-->
<!--                            >-->
<!--                                <b-form-group-->
<!--                                        label-cols-sm="3"-->
<!--                                        label="Street:"-->
<!--                                        label-align-sm="right"-->
<!--                                        label-for="nested-street"-->
<!--                                >-->
<!--                                    <b-form-input id="nested-street"></b-form-input>-->
<!--                                </b-form-group>-->

<!--                                <b-form-group-->
<!--                                        label-cols-sm="3"-->
<!--                                        label="City:"-->
<!--                                        label-align-sm="right"-->
<!--                                        label-for="nested-city"-->
<!--                                >-->
<!--                                    <b-form-input id="nested-city"></b-form-input>-->
<!--                                </b-form-group>-->

<!--                                <b-form-group-->
<!--                                        label-cols-sm="3"-->
<!--                                        label="State:"-->
<!--                                        label-align-sm="right"-->
<!--                                        label-for="nested-state"-->
<!--                                >-->
<!--                                    <b-form-input id="nested-state"></b-form-input>-->
<!--                                </b-form-group>-->

<!--                                <b-form-group-->
<!--                                        label-cols-sm="3"-->
<!--                                        label="Country:"-->
<!--                                        label-align-sm="right"-->
<!--                                        label-for="nested-country"-->
<!--                                >-->
<!--                                    <b-form-input id="nested-country"></b-form-input>-->
<!--                                </b-form-group>-->

<!--                                <b-form-group-->
<!--                                        label-cols-sm="3"-->
<!--                                        label="Ship via:"-->
<!--                                        label-align-sm="right"-->
<!--                                        class="mb-0"-->
<!--                                >-->
<!--                                    <b-form-radio-group-->
<!--                                            class="pt-2"-->
<!--                                            :options="['Air', 'Courier', 'Mail']"-->
<!--                                    ></b-form-radio-group>-->
<!--                                </b-form-group>-->
<!--                            </b-form-group>-->
<!--                        </b-card>-->

                        <b-button class="pull-right" type="submit" variant="primary">Salva</b-button>
                        <b-button type="reset" variant="danger">Indietro</b-button>
                        </b-form>
                    </template>
                </KTPortlet>
                <!--end:: Widgets/Activity-->
            </div>

        </div>
        <div v-if="!isNew()" v-permission="['contract.template.view','contract.template.create','contract.template.edit','contract.template.delete']">
            <div class="row">
                <div class="col-md-12">
                    <!--begin:: Widgets/Activity-->
                    <KTPortlet
                            v-bind="{
            }"
                    >
                        <template v-slot:title>
                            <h4 class="">Template PDF</h4>
                        </template>
                        <template v-slot:toolbar>
                            <b-button-toolbar  v-permission="['contract.template.create']"
                                               aria-label="Toolbar with button groups and input groups"
                            >
                                <b-button-group size="sm" class="mr-1" v-permission="'contract.template.create'">
                                    <b-button  :to="{name:'document-edit-template',params:{'did':currentEditId(),'id':null}}" class="mr-3" variant="primary">Crea nuovo template</b-button>
                                    <!--<v-btn color="primary" dark class="mb-2" v-on="on">New Item</v-btn>-->
                                </b-button-group>
                            </b-button-toolbar>
                        </template>
                        <template v-slot:body>




                            <v-card>
                                <v-card-title>
                                    Elenco
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                            v-model="template.search"
                                            append-icon="search"
                                            label="Cerca"
                                            single-line
                                            hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                        v-model="template.selected"
                                        :headers="template.headers"
                                        :items="template.items"
                                        :single-select="template.singleSelect"
                                        :sort-by="['updated_at']"
                                        :sort-desc="[true]"
                                        :items-per-page="200"
                                        :footer-props="footerProps"
                                        multi-sortNO
                                        item-key="id"
                                        show-selectNO
                                        class="elevation-1"
                                        :search="template.search"
                                        v-bind:class="{ 'kt-spinner-f': template.loading }"
                                >
                                    <template v-slot:item.created_at="{ item }">
                                        <span>{{item.created_at | formatDate}}</span>
                                    </template>
                                    <template v-slot:item.updated_at="{ item }">
                                        <div v-if="item.updated_not_recently" style="height:10px;width:10px;background:red;display:inline-block;border-radius: 100%;margin-right: 5px"></div>
                                        <div v-if="item.updated_recently" style="height:10px;width:10px;background:yellow;display:inline-block;border-radius: 100%;margin-right: 5px"></div>
                                        <div v-if="item.updated_very_recently" style="height:10px;width:10px;background:green;display:inline-block;border-radius: 100%;margin-right: 5px"></div>
                                        <span>{{item.updated_at | formatDate}}</span>
                                    </template>
                                    <!--                                    <template v-slot:top>
                                                                            <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                                                                        </template>-->
                                    <template v-slot:item.actions="{ item }">
                                        <v-icon  v-permission="'contract.template.edit'"
                                                 small
                                                 class="mr-2"
                                                 @click="editTemplateItem(item)"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        <div class="inlineblock"><click-confirm>
                                            <v-icon  v-permission="'contract.template.delete'"
                                                     small
                                                     @click="deleteTemplateItem(item)"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </click-confirm></div>
                                    </template>
                                </v-data-table>
                            </v-card>


                        </template>
                    </KTPortlet>
                    <!--end:: Widgets/Activity-->
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "../../../store/breadcrumbs.module";
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import UserService from "../../../common/user.service";
    import ApiService from "../../../common/api.service";
    import Vue from "vue";

    export default {
        data() {
            return {
                isDraft:false,
                loading:false,
                formoptions:{
                    esignatureaccount:[]
                },
                formconfig:{},
                finalformconfiguration:null,
                form: {
                    label: '',
                    esignatureaccount:null,
                    opts:{

                    },
                },
                footerProps: {'items-per-page-options': [200]},
                template: {
                    loading: false,
                    singleSelect: false,
                    selected: [],
                    search: '',
                    headers: [
                        {text: 'ID', value: 'id'},
                        {
                            text: 'Label',
                            align: 'left',
                            value: 'label',
                        },
                        {text: 'Updated At', value: 'updated_at'},
                        {text: 'Created At', value: 'created_at'},

                    ],
                    items: [],
                }
            }
        },
        components: {
            KTPortlet
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Documenti", route: "/document" },
                { title: !this.isNew()?"Modifica documento":'Nuovo documento' }
            ]);
        },

        created () {
            this.initialize()
        },
        methods:{
            isNew: function(){
                return !this.currentEditId();
            },
            currentEditId: function(){
                return this.$router.currentRoute.params.id;
            },
            allowEditFinalForm: function(){
                return UserService.isSuperDebugUser();
            },
            onSubmit(evt) {
                evt.preventDefault()
                //alert(JSON.stringify(this.form))
                this.loading=true;
                let datasend = {
                    'label':this.form.label,
                    'esignature_account_id':this.form.esignatureaccount,
                    'form':this.formconfig,
                    'opts':this.form.opts,
                };
                if(this.allowEditFinalForm()){
                    datasend.form = this.finalformconfiguration?this.finalformconfiguration:'{}';
                }
                ApiService.post('contracts'+(this.isNew()?'':'/'+this.currentEditId()),datasend)
                    .then(({data}) => {
                        if(this.isNew()){
                            Vue.customNotifySuccess('Salvato');
                            this.$router.push({ name: "document-edit", 'params':{'id':data.id} });
                            this.loading = false;
                            this.reload();
                        }else {
                            Vue.customNotifySuccess('Salvato');
                            this.loading = false;
                            this.reload();
                        }
                        //
                        //
                        //https://bootstrap-vue.js.org/docs/components/toast/
                        // this.$bvToast.toast(`This is toast number ${this.toastCount}`, {
                        //     title: 'BootstrapVue Toast',
                        //     autoHideDelay: 5000,
                        //     appendToast: append
                        // })

                    })
                    .catch(({response}) => {
                        //context.commit(SET_ERROR, response.data.errors);
                        Vue.customNotifyError('Errore',response.data&&response.data.errors?response.data.errors.join(', '):'Si prega di riprovare');
                    });
            },
            onReset(evt) {
                this.$router.go(-1);
                // evt.preventDefault()
                // // Reset our form values
                // this.form.email = ''
                // this.form.name = ''
                // this.form.food = null
                // this.form.checked = []
                // // Trick to reset/clear native browser form validation state
                // this.show = false
                // this.$nextTick(() => {
                //     this.show = true
                // })
            },
            initialize () {
                this.isDraft = this.$route.name === 'document-draft-view';

                if(UserService.hasPermission(['contract.template.edit','contract.template.delete'])){
                    this.template.headers.push({ text: 'Actions', value: 'actions', sortable: false });
                }
                // Load options
                const that = this;
                ApiService.get('esignatureaccounts')
                    .then(({data}) => {
                        that.formoptions.esignatureaccount = [];
                        data.forEach((x)=>{
                            that.formoptions.esignatureaccount.push({value:x.id,text:x.label});
                        });
                        this.reload();
                    })
                    .catch(({response}) => {
                        //context.commit(SET_ERROR, response.data.errors);
                    });
            },
            reload(){
                const that = this;
                if(!this.isNew()) {
                    this.loading=true;
                    this.template.loading = true;
                    ApiService.get('contracts/'+this.currentEditId())
                        .then(({data}) => {
                            this.form.label = data.contract.label;
                            this.form.esignatureaccount = data.contract.esignature_account_id;
                            this.form.opts = JSON.parse(data.contract.opts);
                            this.template.items = data.templates.map(
                                (x) => {
                                    // need 3 boolean on update: very recently 3 days, recently 2 weeks, not recently older
                                    x.updated_very_recently = new Date(x.updated_at) > new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000);
                                    x.updated_recently = new Date(x.updated_at) > new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000);
                                    x.updated_not_recently = !x.updated_very_recently && !x.updated_recently;
                                    return x;
                                }
                            );
                            this.loading=false;
                            this.template.loading = false;
                            if(this.allowEditFinalForm()){
                                this.finalformconfiguration = JSON.parse(data.contract.form);
                            }
                        })
                        .catch(({response}) => {
                            //context.commit(SET_ERROR, response.data.errors);
                        });
                }
            },
            editTemplateItem (item) {
                this.$router.push({ name: "document-edit-template", 'params':{'did':this.currentEditId(),'id':item.id} });
            },

            deleteTemplateItem (item) {
                //const index = this.desserts.indexOf(item)
                ApiService.delete('templates/'+item.id,{'id':item.id}).then(() => this.reload());
            },

        }
    };
</script>
